/* .editor {
    box-sizing: border-box;
    border: 1px solid #ddd;
    cursor: text;
    padding: 16px;
    border-radius: 2px;
    margin-bottom: 2em;
    box-shadow: inset 0px 1px 8px -3px #ABABAB;
    background: #fefefe;
  } */

.editor :global(.public-DraftEditor-content) {
    /* position: relative; */
    background: #f0f2f5;
    border-radius: 15px;
    width: 100%;
    font-size: 15px;
    min-height: 33.84px;
    height: fit-content;
    max-height: 300px;
    overflow-y: scroll;
    overflow-wrap: anywhere !important;
    padding: 5px 25px 5px 10px;
}
.editor :global(.public-DraftEditorPlaceholder-root) {
    z-index: 9;
    font-size: 15px;
    padding-left: 10px;
    padding-right: 10px;
    pointer-events: none;
    height: 33.84px;
    display: flex;
    align-items: center;
}

.editor :global(.DraftEditor-editorContainer) {
    /* background: #f0f2f5; */
    /* border-radius: 15px;
    padding-left: 10px;
    padding-right: 10px;
    height: 33.84px; */
    margin-bottom: 9px;
}

.options {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 2px;
    bottom: 6px;
    z-index: 10;
}


.optionsEditComment {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
}

@media screen and (min-width: 400px) {
    .editor :global(.public-DraftEditor-content) {
        /* min-height: 0; */
        /* max-height: 340px; */
        /* height: 33.84px; */
    }
}

@media screen and (min-width: 688px) {
    .optionsEditComment {
        right: 78px;
        top: 0;
        z-index: 10;
    }
}
