.birthday-header-banner {
    height: 60px;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 6px;
}

.birthday-header-icon {
    /* border-radius: 50%;
    border-style: solid;
    border-width: 0.25px;
    border-color: rgb(189, 189, 189); */
    height: 50px;
    margin-right: 10px;
}

.birthday-header-date {
    justify-content: center;
    color: #737373;
}
.birthday-header-date-span1 {
    font-size: 15px;
}
.birthday-header-date-span2 {
    font-size: 22px;
    line-height: 1;
}