/* .editor {
    box-sizing: border-box;
    border: 1px solid #ddd;
    cursor: text;
    padding: 16px;
    border-radius: 2px;
    margin-bottom: 2em;
    box-shadow: inset 0px 1px 8px -3px #ABABAB;
    background: #fefefe;
  } */

.editor :global(.public-DraftEditor-content) {
    position: relative;
    min-height: 130px;
    max-height: 50vw;
    overflow: scroll;
    
}

.options {
    margin-bottom: 7px;
    display: flex;
    justify-content: flex-end;
}

@media screen and (min-width: 400px) {
    .editor :global(.public-DraftEditor-content) {
        min-height: 140px;
        max-height: 340px;
        overflow: scroll;
    }
}
