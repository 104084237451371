@import "../../../node_modules/primeicons/primeicons.css";

/* Rules for sizing the icon. */
.material-icons.md-18 {
    font-size: 18px;
}
.material-icons.md-22 {
    font-size: 22px;
}
.material-icons.md-24 {
    font-size: 24px;
}
.material-icons.md-30 {
    font-size: 30px;
}
.material-icons.md-36 {
    font-size: 36px;
}
.material-icons-outlined.md-36 {
    font-size: 36px;
}
.material-icons-outlined.md-40 {
    font-size: 40px;
}
.material-icons-outlined.md-48 {
    font-size: 48px;
}
.material-icons.md-48 {
    font-size: 48px;
}
/* Rules for using icons as black on a light background. */
.material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54);
}
.material-icons.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
}
/* Rules for using icons as white on a dark background. */
.material-icons.md-light {
    color: rgba(255, 255, 255, 1);
}
.material-icons.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
}
.material-icon-align {
    vertical-align: bottom;
}
.background-grey {
    background-color: hsl(0deg 0% 98%);
}
.rounded-border {
    border-radius: 15px;
}
.textalign-center {
    text-align: center;
}
.flex-align {
    display: flex;
    align-items: center;
}
.no-bottom-padding {
    padding-bottom: 0px;
}
.text-bold {
    font-weight: 600;
}
.display-none {
    display: none;
}
.position-relative {
    position: relative;
}
.position-absolute {
    position: absolute;
}
.overflow-scroll {
    overflow: scroll !important;
}
.padding-all {
    padding: 10px;
}
.margin-top-7px {
    margin-top: 7px;
}
.margin-top-5px {
    margin-top: 5px;
}
.margin-left-6px {
    margin-left: 6px;
}
.margin-bottom-0px {
    margin-bottom: 0px !important;
}
.fontsize-08em {
    font-size: 0.8em;
}
.fontsize-13px {
    font-size: 13px;
}
.background-white {
    background-color: #f9fafb;
}
.padding-left-35px {
    padding-left: 35px;
}
.padding-top-20px {
    padding-top: 20px;
}
.outline-none {
    outline: none;
}
.outline-none:focus {
    outline: none;
}
.cursor-pointer {
    cursor: pointer;
}
.hover-underline:hover {
    text-decoration: underline;
}
.hover-greyedout:hover {
    filter: brightness(90%);
}
.width-100 {
    width: 100%;
}

.grid-container {
    display: grid;
    grid-template-columns: minmax(100px, 700px);
    justify-content: center;
}

.loader-centered {
    margin-left: calc(50% - 25px);
}

.loader-size {
    width: 50px;
    height: 50px;
}
.post-form-image-dialog > .p-dialog-content {
    overflow: scroll;
}
.post-form-dialog {
    width: 90%;
    height: auto;
    margin: 30px;
}

.post-form-dialog > .p-dialog-content {
    border-radius: 15px;
}
.post-form-options {
    height: 35px;
}
.public-profile-dialog {
    width: 90%;
    height: auto;
    margin: 30px;
    overflow: auto;
}
.telegram-dialog {
    height: auto;
    margin: 30px;
    overflow: auto;
}

.custom-textarea {
    border-style: none;
    font-size: 1em;
}
textarea.custom-textarea:focus {
    outline: 0px;
    box-shadow: none;
}
.post-textarea {
    resize: none;
}
.edit-post-parent .p-dialog .p-dialog-header {
    display: none;
}

.create-post-parent .p-dialog .p-dialog-header {
    display: none;
}

.liked-users-dialog > .p-dialog-header {
    display: none;
}

.liked-users-dialog > .p-dialog-header,
.telegram-dialog > .p-dialog-header {
    border-bottom: 0 none;
    background: #ffffff;
    color: #495057;
    padding: 1rem;
    padding-bottom: 0.33rem;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}
.public-profile-dialog > .p-dialog-header {
    border-bottom: 0 none;
    background: #ffffff;
    color: #495057;
    padding: 1.5rem;
    padding-bottom: 0.33rem;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.telegram-dialog > div > form > div > span > .p-inputtext {
    border-radius: 10px;
    padding-left: 30px;
    padding-right: 10px;
    width: 100%;
    font-size: 17px;
    line-height: 1;
}
.telegram-dialog > div > form > div > span > .p-inputtext:enabled:focus {
    outline: none;
    box-shadow: none;
    border-color: black;
}

.public-profile-dialog
    > .p-dialog-header
    > .p-dialog-header-icons
    > .p-dialog-header-icon,
.telegram-dialog
    > .p-dialog-header
    > .p-dialog-header-icons
    > .p-dialog-header-icon,
.liked-users-dialog
    > .p-dialog-header
    > .p-dialog-header-icons
    > .p-dialog-header-icon {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    /* margin-right: 0.5rem; */
}

.public-profile-dialog
    > .p-dialog-header
    > .p-dialog-header-icons
    > .p-dialog-header-icon:enabled:hover,
.telegram-dialog
    > .p-dialog-header
    > .p-dialog-header-icons
    > .p-dialog-header-icon:enabled:hover,
.liked-users-dialog
    > .p-dialog-header
    > .p-dialog-header-icons
    > .p-dialog-header-icon:enabled:hover {
    color: #495057;
    border-color: transparent;
    background: #e9ecef;
}
.liked-users-dialog {
    overflow: auto;
    width: 30vw;
    min-width: fit-content;
}
.liked-users-dialog > div > div > .profile-name {
    align-self: center;
    margin-left: 8px;
}
.liked-users-header {
    font-size: 20px;
}
.each-liked-user {
    padding-bottom: 5px;
}
.public-profile-dialog > .p-dialog-content {
    padding: 1.5rem;
}

.each-post {
    margin: 13px 4px;
}
.each-post > div > div > .p-card-content {
    padding: 1rem;
    padding-bottom: 3px;
}
.each-post > .p-card > .p-card-body {
    padding: 0;
}

.create-post-parent > div > .p-dialog {
    border: 0px;
}

.post-form-textarea.p-inputtext {
    border-width: 0px;
}
.post-form-textarea.p-inputtext:enabled:focus {
    box-shadow: none;
    border-width: 0;
}

.edit-comment-input > .p-inputtext:enabled:focus,
.create-comment > div > .p-inputtext:enabled:focus {
    box-shadow: none;
    border-color: rgb(0, 0, 0);
}

#post-ellipsis-menu > ul > li.p-submenu-header {
    display: none;
}

.post-galleria > button.p-galleria-close {
    z-index: 1;
    background: rgba(0, 0, 0, 0.2);
    color: #aeb6bf;
}
.post-galleria > button.p-galleria-close:hover {
    background: rgba(0, 0, 0, 0.3);
    color: #ebedef;
}
.post-image-gallery {
    max-width: 100%;
    max-height: 100vh;
    object-fit: contain;
}

.postform-submit.p-disabled {
    /* opacity: 0.75;
    background-color: #eaeaea;
    color: rgb(66, 66, 66);
    border: none; */
    opacity: 0.4;
}
.edit-comment-input > .p-inputtext {
    border-radius: 6px;
}
.create-comment > div > .p-inputtext {
    background: #f0f2f5;    
    border: none;
    height: 33.84px;
    /* width: 92%; */
    width: 100%;
    font-size: 15px;
}
.edit-comment-input > .p-inputtext {
    background: #f0f2f5;
    border: none;
    height: 33.84px;
    font-size: 15px;
}
.p-avatar-circle {
    border-radius: 50%;
    border-style: solid;
    border-width: 0.25px;
    border-color: rgb(189, 189, 189);
    width: 2.5rem;
    height: 2.5rem;
    object-fit: cover;
}
.p-avatar-circle-comment {
    border-radius: 50%;
    border-style: solid;
    border-width: 0.25px;
    border-color: rgb(189, 189, 189);
    width: 33.85px;
    height: 33.85px;
    object-fit: cover;
}

.infinite-scroll-component {
    /* overflow: hidden !important; */
    overflow: visible !important;
}

.posts-rounded-border {
    border-radius: 15px;
    box-shadow: 0px 0px 5px 0px rgb(63 63 63 / 61%);
}

.posts-grid-container {
    display: grid;
    grid-gap: 0px;
    /* grid-template-rows: auto; */
    grid-template-columns: 100%;
}

.posts-header-flex {
    display: flex;
    justify-content: space-between;
}

.comment-form-grid {
    display: grid;
    grid-template-columns: 1fr 10fr 1fr;
}

.comment-form-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.edit-comment-input > .p-inputtext,
.create-comment > div > .p-inputtext {
    border-radius: 15px;
    padding-left: 10px;
    padding-right: 10px;
}

.grid-container-comments {
    display: grid;
    grid-gap: 0px;
    grid-template-rows: auto;
    margin-bottom: 0.5em;
}

.grid-item-comments {
    padding: 0px;
}

.grid-item-child-comments {
    padding: 0px;
    padding-left: 40px;
    font-size: 50%;
}

.flex-container-comment {
    display: flex;
    flex-direction: column;
}

.comment-text {
    overflow-wrap: anywhere;
    font-size: 15px;
}

.comment-buttons {
    align-self: center;
    width: 88px;
}

.comment-buttons-placeholder {
    width: 88px;
}

.comment-reply-button {
    font-size: 12px;
    font-weight: bold;
    color: grey;
}

.comment-reply-button-span:hover {
    text-decoration: underline;
    cursor: pointer;
}

.comment-view-replies {
    color: rgb(152, 162, 255);
    font-weight: bold;
}

.comment-view-replies:hover {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: rgb(152, 162, 255);
}

.post-images {
    border-radius: 10px;
    object-fit: cover;
    width: 100%;
    max-height: 500px;
    margin-top: 0em;
    cursor: pointer;
}

#post-ellipsis-menu {
    width: auto;
}

.flex-container-lc-buttons {
    display: flex;
    justify-content: space-between;
    height: 25px;
    margin-top: 10px;
    font-size: 15px;
}

div.flex-container-lc-buttons > span {
    color: grey;
}

div.flex-container-lc-buttons > span:hover {
    color: grey;
    text-decoration: underline;
    cursor: pointer;
}

.overlay-container {
    position: relative;
    text-align: center;
    color: white;
}

.overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgb(255, 255, 255);
    font-size: 2.5em;
    cursor: pointer;
    pointer-events: none;
}

img.overlay-image {
    filter: brightness(45%);
}

div.read-more-text {
    color: rgb(0, 0, 0);
    cursor: pointer;
    font-weight: bold;
    font-size: 0.9em;
    display: inline;
}

div.read-more-text:hover {
    color: rgb(0, 0, 0);
    text-decoration: underline;
    display: inline;
}

.post-text {
    overflow-wrap: anywhere;
    white-space: pre-wrap;
    font-size: 0.8em;
}

.width-height-100 {
    width: 100%;
    height: 100%;
}

.width-height-inherit {
    width: inherit;
    height: inherit;
}

.dropzone {
    width: 100%;
    height: 100%;
    opacity: 80%;
    display: inline;
    top: 0px;
    left: 0px;
}

.dropzone-with-image {
    width: 100%;
    height: 100%;
    opacity: 80%;
    position: absolute;
    top: 0px;
    left: 0px;
}

.drag-active {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed rgb(0 0 0 / 53%);
    background-color: #dad7d7;
    color: rgb(0, 0, 0);
    border-radius: 15px;
    font-size: 14px;
    text-align: center;
    min-height: 60px;
    height: inherit;
    font-style: italic;
    color: #6d6d6d;
}

.drag-inactive {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed rgb(0 0 0 / 23%);
    border-radius: 15px;
    font-size: 14px;
    text-align: center;
    color: rgb(0, 0, 0);
    min-height: 60px;
    height: inherit;
    background: #80808012;
    font-style: italic;
    color: #6d6d6d;
}

.posts-dropzone-image-grid {
    position: relative;
    /* display: block; */
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto;
    width: 100%;
    height: 100%;
    cursor: pointer;
    padding: 1px;
}

.posts-dropzone-image-grid > img {
    padding: 1px;
}

.posts-img-1 {
    cursor: pointer;
}

.dropzone-image-grid {
    position: relative;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto;
    width: 100%;
    height: 100%;
}

.dropzone-image-grid > img {
    padding: 1px;
}

.drag-drop-upload {
    outline: none;
}

.drag-drop-upload:focus {
    outline: none;
}

.remove-image-btn {
    position: absolute;
    top: 0px;
    right: 0px;
}

.img-1 {
    width: 100%;
    height: 50vw;
    object-fit: cover;
    border-radius: 15px;
    position: relative;
}
.img-2 {
    width: 100%;
    height: 50vw;
    object-fit: cover;
}
.img-2-1 {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}
.img-2-2 {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}
.img-3 {
    width: 100%;
    object-fit: cover;
}
.img-3-1 {
    height: 50vw;
    grid-column: 1/2;
    grid-row: 1/3;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}
.img-3-2 {
    height: 25vw;
    border-top-right-radius: 15px;
}
.img-3-3 {
    height: 25vw;
    border-bottom-right-radius: 15px;
}
.img-4 {
    width: 100%;
    height: 25vw;
    object-fit: cover;
}
.img-4-1 {
    border-top-left-radius: 15px;
}
.img-4-2 {
    border-top-right-radius: 15px;
}
.img-4-3 {
    border-bottom-left-radius: 15px;
}
.img-4-4 {
    border-bottom-right-radius: 15px;
}

.images-dropped-overlay {
    position: absolute;
    bottom: 1px;
    right: 1px;
    width: calc(50% - 2px);
    height: calc(25vw - 2px);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    pointer-events: none;
}

.postform-header-title {
    text-align: center;
    line-height: 37px;
}

#initial-heart-animation {
    width: 160px;
    height: 160px;
    position: relative;
    top: -63px;
    left: -68px;
    pointer-events: none;
}

#heart-animation {
    width: 160px;
    height: 160px;
    position: relative;
    top: -65px;
    left: -66px;
    pointer-events: none;
}

#toggle-like {
    position: relative;
    height: 30px;
    width: 30px;
    top: -7.5px;
    left: 7px;
}

.create-post-parent {
    position: relative;
}

.create-post-button {
    margin-left: 2px;
}

.create-comment {
    margin-left: 5px;
    /* width: 83%; */
    width: 100%;
}
.create-comment-button {
    display: flex;
    padding-bottom: 9px;
    align-items: flex-end;
}
.create-comment-submit {
    background: #6878f0;
    color: white;
    border-radius: 50%;
    padding: 0;
    height: 33.84px;
    width: 33.84px;
    margin-left: 6px;
    border: none;
}
.create-comment-submit:disabled {
    filter: grayscale(100%) opacity(0.3);
}
.create-comment-submit:focus {
    outline: none;
}

.create-comment-send {
    vertical-align: middle;
    margin-top: -4px;
    margin-left: 2px;
}

.edit-comment-container {
    width: 100%;
    margin-left: 6px;
}
.edit-comment {
    display: flex;
    flex-direction: column;
    position: relative;
}
.edit-comment-input {
    width: 100%
}
.edit-comment-buttons {
    align-self: flex-end;
}
.edit-comment-input > textarea {
    width: 99%;
}
.edit-comment-buttons > button:active {
    filter: brightness(90%);
}

.edit-comment-buttons > button:focus {
    outline: none;
}

.edit-comment-buttons > button > span {
    margin-top: 2.5px;
    margin-left: -2px;
}

.edit-comment-submit {
    background-color: #ffffff;
    border-color: transparent;
    border: 1px solid #00000069;
    border-radius: 50%;
    width: 33.84px;
    height: 33.84px;
    margin-right: 3px;
    color: black;
}
.edit-comment-submit:disabled {
    color: lightgray;
    pointer-events: none;
}

.edit-comment-cancel {
    background-color: #ffffff;
    border-color: transparent;
    border: 1px solid #00000069;
    border-radius: 50%;
    width: 33.84px;
    height: 33.84px;
}

.edit-comment-submit:focus {
    outline: none;
}

.edit-comment-cancel:hover {
    cursor: pointer;
}

.like-button {
    background: #607d8b;
    border: 1px solid #607d8b;
    background-color: transparent;
    color: #607d8b;
    border-color: transparent;
}

.like-button:focus {
    outline: 0;
}

.header-postform {
    position: relative;
    margin-bottom: 6px;
}

.postform-cancel {
    background: rgb(255 255 255 / 20%);
    position: absolute;
    top: 0px;
    right: 0px;
    border-radius: 50%;
    border: 2px solid #00000047;
    color: #0000007d;
}
.postform-cancel:hover {
    background: red;
    border: 2px solid red;
    color: #fffffff7;
}

.postform-cancel:hover {
    cursor: pointer;
}

.postform-buttons {
    justify-content: space-between;
    display: flex;
}

.postform-submit {
    margin-top: 1em;
    width: 100%;
    border-radius: 8px;
}
.error-back-button {
    border-radius: 8px;
}

.viewimages-remove {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    padding: 5px;
    border-radius: 1em;
    background-color: rgb(255 255 255 / 71%);
}

.viewimages-remove:hover {
    background-color: rgb(255 255 255 / 94%);
}

.editpost-submit {
    margin-top: 1em;
    width: 100%;
    border-radius: 8px;
}

.image-upload-button:hover {
    background-color: rgb(244 244 244 / 94%);
}
.image-upload-button:focus {
    outline: none;
}
.add-image-button {
    position: absolute;
    top: 4px;
    left: 6px;
    z-index: 6;
}

.clear-images {
    position: absolute;
    top: 2px;
    right: 4px;
    z-index: 6;
}

.view-all-images {
    position: absolute;
    top: 44px;
    right: 4px;
    z-index: 5;
}

.image-dialog-back {
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
    padding: 5px;
    border-radius: 1em;
}

.image-dialog-back:hover {
    background-color: rgba(128, 128, 128, 0.205);
}

.images-dialog-ul-flex {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    margin-top: 1em;
}

.images-dialog-li {
    max-height: 30vh;
    flex-grow: 1;
    margin: 5px;
    position: relative;
    background-color: black;
    text-align: center;
}

.images-dialog-img {
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
}

/* .form-flex-item {
    flex-grow: 1;
    position: relative;
} */
aside.emoji-picker-react {
    max-width: 70vw;
}
.emoji-picker {
    position: absolute;
    z-index: 100;
}
.post-form-options > .emoji-appear-top {
    bottom: -39px;
    right: 30px;
}
.post-form-options > .emoji-appear-bottom {
    top: -23px;
    right: 30px;
}
.emoji-appear-top {
    bottom: -39px;
    right: 47px;
}
.emoji-appear-bottom {
    top: -23px;
    right: 47px;
}
.post-form-options > .emoji-picker-button {
    position: absolute;
    bottom: 5px;
    right: 2px;
    color: grey;
    cursor: pointer;
}
.emoji-picker-button {
    z-index: 9;
    position: absolute;
    bottom: 5.5px;
    right: 6px;
    color: grey;
    cursor: pointer;
}
input.emoji-search {
    display: none;
}
.skin-tones-list {
    display: none;
}

.useravatar-parent {
    position: relative;
    display: flex;
}
.mention {
    background-color: transparent;
    color: #1876f2;
}
.mention:hover {
    background-color: transparent;
    text-decoration: underline;
    color: #1876f2;
}
.mention-profile-preview-container {
    background: white;
    z-index: 10;
    border-radius: 15px;
    box-shadow: 0px 0px 8px 2px rgb(0 0 0 / 20%);
}
.mention-profile-preview-container .profile-preview {
    padding: 5px;
}
.profile-preview-container {
    display: flex;
    flex-direction: column;
    background: white;
    z-index: 11;
    width: max-content;
    height: max-content;
    border-radius: 15px;
    overflow: hidden;
    position: absolute;
    box-shadow: 0px 0px 8px 2px rgb(0 0 0 / 20%);
}
.preview-appear-bottom {
    top: 42px;
    left: 0;
}
.preview-appear-top {
    bottom: 110%;
    left: 0;
}
.profile-preview-container > div {
    margin: 5px;
}
.profile-preview {
    display: flex;
}
.profile-preview > div {
    width: auto;
    min-width: 20px;
    margin: 5px;
    align-self: center;
}
.profile-preview-picture {
    border-radius: 50%;
    border-style: solid;
    border-width: 0.25px;
    border-color: rgb(189, 189, 189);
    width: 5.5rem;
    height: 5.5rem;
}
.preview-companylogo {
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    top: 0;
    right: 0;
    margin: 5px;
}
.user-description {
    display: flex;
    flex-direction: column;
    font-size: 19.5px;
    max-width: 300px;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
}
.user-jobtitle {
    color: grey;
    font-size: 13.5px;
}
.user-companyname {
    color: grey;
    font-size: 13.5px;
}
.company-banner {
    width: 100px;
}
.profile-preview-button {
    text-align: center;
}

.timestamp {
    font-size: 0.8em;
    color: #777777;
}

.cursor-underline:hover {
    text-decoration: underline;
    cursor: pointer;
}

.profile-page-container {
    display: flex;
    flex-direction: column;
    background-color: rgb(223 222 221);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
.profile-page-container > div {
    margin-bottom: 5px;
    padding: 13px;
}
.profile-page {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 74px;
    pointer-events: none;
}
.profile-page > div {
    width: auto;
    min-width: 20px;
}
.profile-page-cover {
    position: absolute;
    top: 0;
    left: -13px;
    width: 122%;
    height: 133px;
    object-fit: cover;
    cursor: pointer;
}
.profile-page-displaypic {
    border-radius: 50%;
    border-style: solid;
    border-width: 6px;
    border-color: rgb(249 250 251);
    background-color: rgb(249 250 251);
    width: 8em;
    height: 8em;
    pointer-events: auto;
    cursor: pointer;
    object-fit: cover;
}
.profile-user-description {
    padding: 5px;
    /* position: absolute;
    bottom: 10px;
    left: 120px; */
    pointer-events: auto;
}
.user-info-section-1 {
    width: 50%;
}
.user-info-section-2 {
    width: 50%;
}

.public-profile-container > * > * {
    pointer-events: all;
}
.public-profile-info {
    display: flex;
    flex-direction: column;
}
.public-profile-info > * {
    width: 100%;
    padding: 10px 0px;
}
.profile-work-info > table > tbody > tr > td {
    padding: 0;
    padding-left: 7px;
}
.profile-personal-info > table > tbody > tr > td {
    padding: 0;
    padding-left: 15px;
}
.profile-personal-info > table > tbody > tr > th {
    padding-left: 7px;
}
.public-profile-table {
    height: min-content;
}

.profile-page-name {
    font-size: 20px;
    line-height: 1;
}
.profile-page-jobtitle {
    font-size: 17px;
    line-height: 1;
}
.profile-company {
    height: fit-content;
    width: 50%;
    display: grid;
    grid-auto-rows: 1fr;
}
.profile-companyname {
    font-size: 14px;
}
.profile-departmentname {
    font-size: 14px;
}
.profile-companylogo {
    border-radius: 25%;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 7px;
    margin-top: 4px;
    border: 1px solid #00000017;
    object-fit: contain;
}
.profile-card-title {
    font-size: 1.4em;
    margin-bottom: 5px;
}

.profile-card-edit-button {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}
.user-info-section > div > .profile-card-edit-button {
    top: -60px;
    right: 0px;
    pointer-events: all;
}
.user-info-section-1 > * {
    pointer-events: all;
}
.user-info-section-2 > * {
    pointer-events: all;
}
.email-link {
    margin-right: 6px;
}
.email-link > .p-button {
    background: #d55244;
    border: 1px solid #d55244;
}
.email-link > .p-button:hover {
    background: #bd483b;
    border: 1px solid #bd483b;
}
.email-link:hover {
    text-decoration: none;
}
.telegram-link > .p-button {
    background: #2196f3;
    border: 1px solid #2196f3;
}
.telegram-link > .p-button:hover {
    background: #0d89ec;
    border: 1px solid #0d89ec;
}
.telegram-link:hover {
    text-decoration: none;
}
.add-telegram {
    width: fit-content;
}
.add-telegram > button > span {
    font-weight: 600;
}
.add-telegram > .p-button.p-button-secondary.p-button-outlined {
    border: 2px solid;
}
.add-telegram > .p-button.p-button-secondary.p-button-outlined:hover {
    border: 2px solid;
}
.edit-telegram-button {
    cursor: pointer;
    position: absolute;
    top: 8px;
    right: -44px;
}
/* fullscreen profile cover */
.fullscreen-displaypic {
    position: fixed;
    top: 50%;
    left: 50%;
    max-width: 100%;
    z-index: -1;
    transform: translate(-50%, -50%);
    object-fit: contain;
    max-height: 100%;
}
.fullscreen-background-displaypic {
    background-color: black;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: auto;
}
.fullscreen-cover {
    position: fixed;
    top: 50%;
    left: 50%;
    max-width: 100%;
    z-index: -1;
    transform: translate(-50%, -50%);
    object-fit: contain;
    max-height: 100%;
}
.fullscreen-background-cover {
    background-color: black;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
}
.fullscreen-close {
    color: white;
    margin: 10px;
    background-color: #00000094;
    border-radius: 50%;
    pointer-events: auto;
    position: fixed;
    top: 0;
    left: 0;
    cursor: pointer;
}

.user-info-section {
    display: flex;
    justify-content: flex-start;
}
.company-info-list {
    list-style: none;
    padding: 0;
}

.border-red {
    border: 1px solid red;
}

.border-blue {
    border: 1px solid blue;
}
.flex-row {
    display: flex;
}
.flex-column {
    display: flex;
    flex-direction: column;
}
.each-comment {
    margin-bottom: 2px;
}
.comment-bubble {
    background-color: #f0f2f5;
    border-radius: 15px;
    padding: 7px;
    width: fit-content;
    margin-left: 5px;
}
.align-items-center {
    align-items: center;
}
.number-of-likes {
    color: grey;
    margin-left: 10px;
    margin-top: -5px;
    cursor: pointer;
}
.number-of-likes:hover {
    text-decoration: underline;
}

.reply-comment-input {
    margin-left: 29px;
}

.view-replies-parent {
    padding-bottom: 3px;
    font-size: 15px;
}

.reply-and-timestamp {
    margin-bottom: -4px;
    margin-left: 7px;
}

.child-comments {
    margin-bottom: 3px;
    margin-left: 28.5px;
}

.interpunct {
    margin: 0px 2px;
}
.comment-author {
    line-height: 1;
}
.confirm-delete-dialog {
    border-radius: 15px;
    overflow: auto;
    border: 0px;
}
.confirm-delete-dialog .p-dialog-header {
    display: none;
}
.commentuserdiv {
    border: 1px solid red;
}
.input-at-icon {
    position: absolute;
    top: 6px;
    left: 4px;
    color: grey;
}
.whitespace-prewrap {
    white-space: pre-wrap;
}

.image-upload-button-text {
    display: none;
}
.image-upload-button {
    border: none;
    background-color: rgb(255 255 255 / 71%);
    color: black;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 12px;
    font-weight: 600;
    border: 1px solid #80808026;
    text-indent: 0px;
    padding: 5px 5px;
}
.post-form > .post-form-textarea {
    max-height: 230px;
}
.timestamp .timestamp-tooltip {
    width: max-content;
    background-color: #000000c7;
    color: #fff;
    text-align: center;
    padding: 3px 10px;
    border-radius: 6px;
    position: absolute;
    bottom: -26px;
    left: -45px;
    z-index: 10;
    transition: 0s;
    transition-delay: 0.2s;
    visibility: hidden;
}
.timestamp:hover .timestamp-tooltip {
    transition-delay: 0.5s;
    visibility: visible;
}

.create-post-card > .p-card-body > .p-card-content {
    padding: 0;
}
.create-post-card-open {
    border-radius: 15px;
    background-color: #f0f2f5;
    width: 100%;
    line-height: 2.4;
    margin-left: 8px;
}
.create-post-card-text {
    left: 10px;
    color: #565656;
    font-size: 17px;
}
.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.view-more-comments {
    cursor: pointer;
    font-size: 15px;
    font-weight: 600;
    color: #65676b;
}
.view-more-comments:hover {
    text-decoration: underline;
}
.public-profile-textarea > textarea {
    width: 100%;
}
.public-profile-textarea > .p-inputtext {
    font-size: 14px;
}
.public-profile-textarea > .p-inputtext:enabled:focus {
    box-shadow: none;
}

/* emojiSuggestions:CSS class for suggestions component */
.esyutjr {
    z-index: 10;
}
/* emoji Popover */
.ejr02pv {
    margin-top: -274px;
    margin-left: -271px;
    border-radius: 7px;
}
/* .ejr02pv {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
} */

.e1kg9q3n {
    width: 16em;
    height: 16em;
}
.e1cibj9i {
    width: 16em;
}
/* emojiSelectButton styles */
.e8k2yoa {
    margin-right: 4px;
    width: 21px;
    height: 21px;
    line-height: unset;
    border: none;
    position: relative;
}
.e13wqaj6 {
    margin-right: 4px;
    width: 21px;
    height: 21px;
    line-height: unset;
    border: none;
    position: relative;
}
.draftjs-emoji-button-span {
    font-size: 34px;
    position: absolute;
    bottom: -10px;
    left: -7px;
    pointer-events: none;
}

.draftjs-readonly {
    font-size: 13px;
}






@media screen and (min-width: 400px) {
    .draftjs-readonly {
        font-size: 16px;
    }
    .public-profile-textarea > .p-inputtext {
        font-size: 16px;
    }
    .emoji-appear-top {
        bottom: -39px;
        right: 33px;
    }
    .emoji-appear-bottom {
        top: -23px;
        right: 33px;
    }
    .post-form > .post-form-textarea {
        max-height: 31vh;
    }
    .clear-images {
        top: 3px;
        right: 5px;
    }
    .view-all-images {
        top: 54px;
        right: 4px;
    }
    .image-upload-button {
        text-indent: -2px;
        padding: 10px 20px;
        border-radius: 16px;
    }
    .image-upload-button-text {
        display: inline-block;
    }
    .emoji-picker-button {
        right: 6px;
    }
    .create-comment > div > .p-inputtext {
        width: 100%;
    }
    .create-comment-submit {
        right: -37px;
    }
    .loader-size {
        width: 100px;
        height: 100px;
    }
    .loader-centered {
        margin-left: calc(50% - 50px);
    }

    .post-text {
        font-size: 1em;
    }

    .dropzone-image-grid {
        display: grid;
    }
    /* .posts-dropzone-image-grid {
        display: grid;
    } */
    /* .drag-drop-upload {
        height: 50vw;
    } */
    .img-1 {
        height: 50vw;
        width: 100%;
    }
    .img-2 {
        height: 50vw;
    }
    .img-2-1 {
        border-top-left-radius: 15px;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 15px;
    }
    .img-2-2 {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 15px;
        border-top-right-radius: 15px;
    }
    .img-3-1 {
        height: 50vw;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 15px;
    }
    .img-3-2 {
        height: 25vw;
        border-top-right-radius: 15px;
    }
    .img-3-3 {
        height: 25vw;
        border-bottom-left-radius: 0px;
    }
    .img-4 {
        height: 25vw;
    }
    .img-4-1 {
        border-top-right-radius: 0px;
    }
    .img-4-2 {
        border-top-right-radius: 15px;
    }
    .img-4-3 {
        border-bottom-left-radius: 15px;
    }
    .img-4-4 {
        border-bottom-left-radius: 0px;
    }
    .images-dropped-overlay {
        height: calc(25vw - 2px);
        width: calc(50% - 2px);
        border-bottom-left-radius: 0px;
    }
    .drag-inactive {
        min-height: 80px;
        font-size: 16px;
    }
    .drag-active {
        min-height: 80px;
        font-size: 16px;
    }
    .post-form-textarea.p-inputtext {
        font-size: 16px;
    }
    .post-form-textarea.p-inputtext:enabled:focus {
        font-size: 16px;
    }
}

@media screen and (min-width: 688px) {
    .profilepic-userdescription-div {
        display: flex;
    }
    /* .post-form-dialog > .p-dialog-content {
        overflow: visible;
    } */
    .create-post-button {
        margin-left: 29px;
    }
    .edit-comment {
        flex-direction: row;
        align-self: auto;
    }
    .edit-comment-input > textarea {
        width: 99%;
    }
    .edit-comment-input {
        width: 100%;
    }
    .edit-comment-buttons {
        width: 83px;
        margin-left: 5px;
        align-self: auto;
    }
    .each-post {
        margin: 15px 30px;
    }
    .profile-page-container {
        position: relative;
        background-color: transparent;
        align-items: center;
    }
    .profile-page-container > div {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        margin-bottom: 10px;
        padding: 20px;
        transform: none;
        width: 800px;
    }
    .profile-page-cover {
        height: 180px;
        width: 800px;
        border-radius: 10px 10px 0 0;
        top: -20px;
        left: -20px;
    }
    .public-profile-info {
        flex-direction: row;
    }
    .fullscreen-background-cover {
        width: 200%;
        height: 200%;
        top: -66px;
        left: -266px;
    }
    .profile-page-displaypic {
        width: 12em;
        height: 12em;
    }
    .profile-user-description {
        /* position: static; */
        padding: 0px;
        margin-top: 98px;
        margin-left: 17px;
        /* bottom: 20px;
        left: 190px; */
    }
    .profile-page-name {
        font-size: 28px;
    }
    .profile-page-jobtitle {
        font-size: 22px;
    }
    .profile-companyname {
        font-size: 16px;
    }
    .profile-departmentname {
        font-size: 16px;
    }
    .post-form-dialog {
        width: 600px;
    }
    .public-profile-dialog {
        width: 600px;
    }
    .telegram-dialog {
        width: 330px;
    }
    .img-1 {
        height: 350px;
    }
    .img-2 {
        height: 350px;
    }
    .img-3-1 {
        height: 350px;
    }
    .img-3-2 {
        height: 175px;
    }
    .img-3-3 {
        height: 175px;
    }
    .img-4 {
        height: 175px;
    }

    .images-dropped-overlay {
        height: calc(175px - 2px);
    }
    /* .drag-inactive {
        font-size: 16px;
    }
    .drag-active {
        font-size: 16px;
    } */
}
