/* General */
$fontSize:14px;
$bodyBgColor:#f9fafb;
$textColor:#333333;
$textSecondaryColor:#707070;
$borderRadius:5px;
$dividerColor:#e3e3e3;
$transitionDuration:.2s;
$maskBgColor:#424242;

/* Menu Common */
$menuitemBadgeBgColor:#007be5;
$menuitemBadgeColor:#ffffff;
$submenuFontSize:13px;
$menuitemActiveRouteColor:#6777ef;

/* Menu Light */
$menuBgColorFirst:#ffffff;
$menuBgColorLast:#ffffff;
$menuitemColor:#707070;
$menuitemHoverColor:#6777ef;
$menuitemActiveColor:#6777ef;
$menuitemActiveBgColor:#f9fafb;
$menuitemBorderColor:rgb(255, 255, 255);

/* Menu Dark */
$menuDarkBgColorFirst:#4d505b;
$menuDarkBgColorLast:#3b3e47;
$menuitemDarkColor:#ffffff;
$menuitemDarkHoverColor:#0388e5;
$menuitemDarkActiveColor:#0388e5;
$menuitemDarkActiveBgColor:#2e3035;
$menuitemDarkBorderColor:rgba(52, 56, 65, 0.6);

/* Topbar */
$topbarLeftBgColor:#6777ef;
$topbarRightBgColor:#6777ef;
// $topbarLeftBgColor:#77A3B1;
// $topbarRightBgColor:#77A3B1;
$topbarItemBadgeBgColor:#ef6262;
$topbarItemBadgeColor:#ffffff;
$topbarItemColor:#ffffff;
$topbarItemHoverColor:#c3e8fb;
$topbarSearchInputBorderBottomColor:#ffffff;
$topbarSearchInputColor:#ffffff;

/* Footer */
$footerBgColor:#f9fafb;