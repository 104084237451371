html {
  height: 100%;
}

#content {
  height: 100%;
}
// Forms
.requiredRed {
  color: red;
}

div.premium {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  filter: blur(5px);

  // Disable Selection
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}
span.premium {
  color: red;
}

//Suggested location to add your overrides so that migration would be easy by just updating the SASS folder in the future
body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  font-family: "Nunito", sans-serif;
}

table {
  font-family: "Nunito", sans-serif;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  background: #bbbbbb;
  border-radius: 10px;
  box-shadow: rgba(255, 255, 255, 0.712) 0 0 0 1px;
}
:hover::-webkit-scrollbar-thumb {
  background: #bbbbbb;
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.55);
}

/* Moved over from new.css */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700 !important;
  color: #454a5b !important;
}

/* Pick List */

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .p-picklist {
    width: 60% !important;
  }
}

/* Medium devices (tablets, 768px and up)  */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* When responsive table becomes mobile view  */
@media (max-width: 640px) {
  body .p-datatable .p-datatable-tbody > tr {
    border-top: 1px solid #c8c8c8 !important;
    border-bottom: 2px solid #c8c8c8 !important;
  }
}

@media (max-width: 576px) {
  .editButtonFloatRight {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    display: block !important;
  }
}

@media (min-width: 576px) {
  .editButtonFloatRight {
    float: right;
  }
}

#invoice-table {
  font-size: smaller;
}

.boldLabel {
  font-weight: bold;
}

button :disabled {
  opacity: 0.1;
}

/* Data tables */
body .p-datatable .p-datatable-tbody > tr > td {
  border: 0px none !important;
}

body .p-datatable .p-datatable-thead > tr > th {
  border: 0px none !important;
}

body .p-datatable .p-datatable-header {
  background-color: #ffffff !important;
  border: 0px none !important;
}

body .p-paginator {
  border: 0px none !important;
  background-color: #f4f4f4 !important;
}

/* Display Picture */
.imagecropper {
  width: 160px;
  height: 160px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  text-align: center;
  border: 1px black;
  border-style: double;
}

.imagecropper img {
  margin: 0 auto;
  height: 100%;
  width: 160px;
  object-fit: contain;
}

.overviewProfilePic {
  margin: 0 auto;
  height: 80px;
  width: 80px;
  display: inline-block;
  border-radius: 50%;
}

.payslip-pic {
  max-width: 100%;
  max-height: 100%;
}

.companyImageCropper {
  width: auto;
  height: auto;
  position: relative;
  overflow: hidden;
  text-align: center;
  border: 1px lightgrey;
  border-style: double;
  max-height: 500px;
  max-width: 500px;
}

.companyImageCropper img {
  margin: 0 auto;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.imagecropper-small {
  width: 60px;
  height: 60px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  text-align: center;
  border: 1px #000000;
  border-style: double;

  position: relative;
  display: inline-block;
}

.imagecropper-small img {
  height: 60px;
  width: 60px;
  object-fit: cover;
}

/* Dialog  */
.popupDialog {
  padding: 2px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 305px;
}

.popupDialogModified {
	padding: 2px;
  	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  	width: 400px;
}

.popupDialog .p-dialog-content {
  text-align: center;
}

.popupDialogKPI {
  width: 610px;
}

@media (max-width: 768px) {
  .popupDialogKPI {width: 90%;}
}
@media (max-height: 750px) {
  .popupDialogKPI {width: 90%; height:90%; overflow-y:scroll}
}

/* React Tooltip */
.tooltipWidth {
  width: 300px;
}

/* List view */
.exclamationStyle {
  z-index: 1;
  margin-top: 10px;
  color: red;
}

.informationStyle {
  z-index: 1;
  margin-top: 10px;
  color: blue;
}

.informationStyleSmall {
  z-index: 1;
  // margin-top: 10px;
  color: blue;
  vertical-align: top;
}

.fas.fa-exclamation.exclamationStyle {
  float: right;
  display: inline-table;
}

.tooltipFont {
  font-family: "Nunito", sans-serif;
  font-size: 14px;
}

.highlightedMenu span {
  color: #6777ef;
}

.highlightedOuterMenu a i span {
  color: #6777ef;
}

/* full calendar stuff */
/* body .fc .fc-toolbar .fc-button {
  color: #ffffff;
  background-color: #44BD88 !important;
  border: 1px solid #44BD88 !important;
  font-size: 14px;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;
  border-radius: 3px;
} */

body .fc .fc-toolbar .fc-button .fc-icon-chevron-right {
  font-size: small !important;
}
body .fc .fc-toolbar .fc-button .fc-icon-chevron-left {
  font-size: small !important;
}

#select-button .p-button-text-only .p-button-text {
  padding: 0.6em 0.1em !important;
  text-align: center !important;
  font-size: 11px !important;
}

#select-button .p-selectbutton .p-button {
  white-space: nowrap !important;
}

#select-button .p-button-text .p-c {
  font-size: 12px !important;
}

/* #leave-overlap-message .p-messages-icon {
  display: none !important;
}

#leave-overlap-message .p-messages .p-messages-summary {
  font-weight: bold;
  margin-left: 0em;
}

#leave-overlap-message .p-messages .p-messages-detail {
  margin-left: 0em;
  display: block;
} */

/* Accordian */

.p-accordion-content {
  border: 0px none !important;
}

.p-accordion-header a {
  border: 0px none !important;
}

.p-accordion-header-text {
  font-family: "Nunito", sans-serif;
  font-size: 24px;
  font-weight: 700 !important;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight a {
  background-color: #6777ef !important;
}

body .fc .fc-event {
  background-color: #bed1e6 !important;
  border: 1px solid #bed1e6 !important;
  color: #000000 !important;
}

.fc-unthemed td.fc-today {
  background: #fbe9e7 !important;
}

.p-button-info {
  background-color: #e0fefe;
}

.p-overlaypanel:before {
  display: none !important;
}

.p-overlaypanel:after {
  display: none !important;
}

.namePadding {
  width: 230px;
  padding-left: 10px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-top: auto;
  margin-bottom: auto;
}

.companyOverview .p-accordion-header-text {
  font-size: 18px !important;
  width: 230px;
}

/**
* Datatable Overwrite
*/
body .p-datatable .p-datatable-thead > tr {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
body .p-datatable .p-datatable-thead > tr > th {
  background-color: #fefefe;
  color: #676767;
  text-transform: uppercase;
  overflow-wrap: break-word;
}
body .p-datatable .p-datatable-tbody > tr {
  background-color: unset;
  border-bottom: 1px solid #eee;
  color: #676767;
}
// body .p-datatable .p-datatable-tbody > tr:nth-child(even) {
//   background-color: unset;
// }

.fc-dayGridMonth-button {
  font-weight: bold !important;
  background-color: #cccccc !important;
  border-color: #cccccc !important;
}

.fc-timeGridWeek-button {
  font-weight: bold !important;
  background-color: #cccccc !important;
  border-color: #cccccc !important;
}

.fc-timeGridDay-button {
  font-weight: bold !important;
  background-color: #cccccc !important;
  border-color: #cccccc !important;
}
.fc-today-button {
  font-weight: bold !important;
  background-color: #007ad9 !important;
  border-color: #cccccc !important;
}

.fc-button-active {
  background-color: #007ad9 !important;
  background-image: none;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
}

.fc-center {
  text-align: center;
}

.fc-next-button {
  border: 0px !important;
  height: fit-content;
}

.fc-prev-button {
  border: 0px !important;
  height: fit-content;
}
.__react_component_tooltip .place-right type-dark .allow_click .tooltipFont .p {
  display: block;
  margin-block-start: 0em !important;
  margin-block-end: 0em !important;
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;
}

#mybookings-table .p-datatable-header {
  padding-left: inherit;
}

body .p-datatable .p-datatable-tfoot > tr > td {
  border: none;
  border-bottom: 1px solid #eee;
}

// for kendo-react-pdf
@font-face {
  font-family: "DejaVu Sans";
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans.ttf")
    format("truetype");
}

@font-face {
	font-family: "ObjectSans-Bold";
  	src: url("../assets/fonts/ObjectSans-Bold.otf")
    format("truetype");
}

@font-face {
	font-family: "ObjectSans-Regular";
	src: url("../assets/fonts//ObjectSans-Regular.otf")
	format("truetype");
}

// Leave history info dialog
.leave-info-dialog td {
  border-top: 0px none;
  border-bottom: 1px solid #dee2e6;
  width: 305px;
}

.layout-menu li a {
  padding: 1em 1.5em;
}

.layout-menu li ul li a {
  cursor: pointer;
  padding: 0.5em 1.75em;
}

.button-document {
  background-color: #2940e8 !important;
  border-color: #2940e8 !important;
}

#room-booking .p-timepicker > .p-separator {
  margin-left: 0;
  min-width: 0.75em;
  vertical-align: top !important;
}

.p-timepicker > div {
  display: table-cell !important;
}

// Noticeboard
// Background moved to _parallax.scss

.noticeboard button#companySettingsGear {
  background-color: inherit;
  border: inherit;
}

.noticeboardTypeHeader {
  font-size: 18px;
  padding-bottom: 2px;
}

.noticeboardCard {
  background-color: rgba(27, 39, 53, 0.35);
}

.noticeboard #datesButtonGroup {
  display: flex;
  align-items: center;
  justify-content: center;
}

.noticeboard #calendarIconTodayBtn,
.noticeboard #calendarIconTodayBtn:hover,
.noticeboard #calendarIconTodayBtn:focus,
.noticeboard #calendarIconTodayBtn:enabled:focus {
  background-color: inherit;
  font-size: 5em;
  border: 0;
  width: auto;
  box-shadow: unset;
}
.noticeboard button#calendarIconTodayBtn > span.p-button-text {
  padding: 0;
}
.noticeboardCard p,
.noticeboardCard span {
  color: #fff;
}
.noticeboardCard h1,
.noticeboardCard h2,
.noticeboardCard h3,
.noticeboardCard h4,
.noticeboardCard h5 {
  color: #e3e3e3 !important;
}

.noticeboardCard span.pendingText {
  // color: #ff0000;
  color: #cf6679;
  font-size: 0.7em;
  letter-spacing: 0.15em;
}
.noticeboardCard p.noticeboardRemarks,
.noticeboardCard span.noticeboardRemarks {
  color: #a9a9a9;
}

.noticeboard .p-button {
  background-color: #6d74a6;
  border: 1px solid #6d74a6;
}
.noticeboard .p-button:hover,
.noticeboard .p-button:enabled:hover {
  background-color: #8a90bf;
  border: 1px solid #8a90bf;
}

// Dashboard

.dashboardFont {
  font-weight: bold;
  font-size: 16px;
}

.disabledCard {
  background-color: #ededed !important;
}

ol.progtrckr {
  list-style-type: none;
  padding: 0;
}

ol.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 4.5rem;
  cursor: pointer;
}

ol.progtrckr li span {
  padding: 0 1.5rem;
}

@media (max-width: 650px) {
  .progtrckr li span {
    display: none;
  }
}
.progtrckr em {
  display: none;
  font-weight: 700;
  padding-left: 1rem;
}

@media (max-width: 650px) {
  .progtrckr em {
    display: inline;
  }
}

ol.progtrckr li.progtrckr-todo {
  color: silver;
  border-bottom: 4px solid silver;
}

ol.progtrckr li.progtrckr-doing {
  color: black;
  border-bottom: 4px solid #6777ef;
}

ol.progtrckr li.progtrckr-done {
  color: black;
  border-bottom: 4px solid #5cb85c;
}

ol.progtrckr li:after {
  content: "\00a0\00a0";
}

ol.progtrckr li:before {
  position: relative;
  bottom: -3.7rem;
  float: left;
  left: 50%;
}

ol.progtrckr li.progtrckr-todo:before {
  content: "\039F";
  color: silver;
  // background-color: white;
  width: 1.2em;
  line-height: 1.4em;
}

ol.progtrckr li.progtrckr-todo:hover:before {
  color: #ff4500;
}

ol.progtrckr li.progtrckr-doing:before {
  content: "\2022";
  color: white;
  background-color: #6777ef;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
}

ol.progtrckr li.progtrckr-doing:hover:before {
  color: #ff4500;
}

ol.progtrckr li.progtrckr-done:before {
  content: "\2713";
  color: white;
  background-color: #5cb85c;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
}

ol.progtrckr li.progtrckr-done:hover:before {
  color: #333;
}
body .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: #b6bfff !important;
}

.settingsFont {
  font-size: 18px;
  color: #6777ef !important;
}

.imgThumbnail {
  max-height: 400px;
  object-fit: contain;
}

.toppost > legend {
  color: #454a5b !important;
  width: initial;
  margin-bottom: initial;
  font-size: initial;
  background: white !important;
  border: 1px solid #c8c8c8 !important;
  font-size: 1.1rem;
}

// dropdown icon background

.p-dropdown .p-dropdown-trigger {
  background-color: #e0e3fb !important ;
}

#noticeboardDropdown > .p-dropdown-trigger {
	background-color: inherit !important;
}

#noticeboardDropdown > .p-dropdown-label {
	font-family: "Nunito", sans-serif;
	font-size: 18px;
	font-weight: bold;
}

.searchIcon {
  position: absolute;
  top: 17px;
  left: 20px;
}

// Payslip

.payslipSections {
  background-color: #dfe9f2;
}

.outerCenterWrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

a[href],
input[type="submit"],
input[type="image"],
label[for],
select,
button,
.pointer {
  cursor: pointer !important;
}

//     ___  _  _  ___   ___   _  _   ___   ___
//    / __|| || || _ \ / _ \ | \| | / _ \ / __|
//   | (__ | __ ||   /| (_) || .` || (_) |\__ \
//    \___||_||_||_|_\ \___/ |_|\_| \___/ |___/
//
.equipmentTable {
  text-align: center !important;
}

.equipmentTable thead.p-datatable-thead th > input.p-inputtext,
.equipmentTable thead.p-datatable-thead th > .p-dropdown {
  font-family: "Nunito", sans-serif;
  text-align: center;
  border-color: #eeeeee;
  color: #676767;
  text-transform: uppercase;
  overflow-wrap: break-word;
  font-weight: 700;
}

.chronosTable {
  // border: 1px solid black;
  font-size: 0.8em;
  width: 100%;
  height: 100%;
}
.chronosTable .p-datatable-frozen-view {
  border-right: 2px solid #eee;
}

.chronosTable
  table.p-datatable-scrollable-body-table
  tbody.p-datatable-tbody
  td.timeslot {
  border: 1px solid #eee !important;
}

.chronosTable
  .p-datatable-scrollable-view.p-datatable-unfrozen-view
  ::-webkit-scrollbar {
  height: 5px;
}

.chronosTable .chronosTableCell,
.chronosTable .chronosTableCell:hover,
.chronosTable .chronosTableCell:active,
.chronosTable .chronosTableCell:focus,
.chronosTable .chronosTableCell:focus-within,
.chronosTable .chronosTableCell:visited {
  width: 100%;
  height: 100%;
  background-color: white;
  color: white;
  border: unset;
  transition: none;
  cursor: pointer;
  padding: unset;
}

.chronosTable .p-datatable-tbody .p-datatable-row td {
  padding: unset !important;
  height: inherit;
}

#equipmentScanningInput {
  border: #eee 1px solid;
}
#equipmentScanningInput:enabled:focus,
#equipmentScanningInput:hover {
  border: none;
  box-shadow: 0 0 0 0.15em #eee;
}

.p-accordion .no-bg a {
  background-color: transparent !important;
}

.p-accordion .no-bg:not(.p-disabled).p-highlight a {
  background-color: transparent !important;
  color: #333333 !important;
}

.p-message .p-message-icon {
  display: none !important;
}

fieldset.equipment-selected-list > legend > a {
  padding: 0.5em;
  font-size: 0.55em;
}

// Homepage

.homepage {
  font-family: "Nunito", sans-serif;
  flex: 1;
}

.btn-darker {
  color: #6777ef !important;
  background-color: #fff !important;
  border-color: #6777ef !important;
  border-width: medium !important;
  font-weight: 700 !important;
}

.btn-darker:hover {
  color: #fff !important;
  background-color: #4853a7 !important;
  border-color: #4853a7 !important;
  border-width: medium !important;
  font-weight: 700 !important;
}

.btn-login {
  color: #fff !important;
  background-color: #6777ef !important;
  border-color: #6777ef !important;
  border-width: medium !important;
  font-weight: 700 !important;
}

.btn-login:hover {
  color: #fff !important;
  background-color: #4853a7 !important;
  border-color: #4853a7 !important;
  border-width: medium !important;
  font-weight: 700 !important;
}

footer.bg-dark {
  background: #252e42 !important;
  color: #f2f2f2;
  padding: 20%;
}

.footertext p {
  color: white !important;
}

.footerwordsh3 {
  color: white !important;
}

.footerwords14 {
  font-size: 14px;
}

.footerwords12 {
  font-size: 12px;
}

.homepagetint {
  background-color: #f4f5f8 !important;
}

.fdb-block {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.lead {
  font-size: 18px !important;
}

.termsandcond .ql-container {
  font-family: "Nunito", "DejaVu Sans", sans-serif !important;
  color: #454a5b !important;
}

.termsandcond p {
  font-size: 10px;
}

.termsandcond h5 {
  font-size: 14px;
}

.featuresimg {
  height: 300px;
}

.colouredbgroundedcard {
  background-color: #f1f0fd !important;
  border-radius: 20px;
}
//price plans page
.premiumbg {
  background-color: #f1f0fd !important;
}

.fa.fa-check.greentick {
  color: #67efd6;
}

.fa.fa-times.greycross {
  color: #bdbdbd;
}

.priceplantable th,
td {
  padding: 10px;
}

.border-premiumcolour {
  border-color: #6777ef !important;
}

.border-freecolour {
  border-color: #e0e0e0 !important;
}

.blackfont {
  color: #000000;
}

// admin dashboard breadcrumbs
.adminDashboardBreadcrumb {
  border: none !important;
  background-color: #f9fafb !important;
}

// invoice
.invoicedeltable td {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.buttonAsLink {
  background: none !important;
  border: none !important;
  padding: 0 !important;
  color: #069 !important;
  text-decoration: underline !important;
  cursor: pointer;
}

// neuhr text
.neuhrTextColor {
  color: #6777ef !important;
}

.irn-button {
  background-color: #6777ef !important;
  border-color: #6777ef !important;
}

.darkerPurple {
  color: #2940e8 !important;
}
.neuhr-ButtonColor {
  background-color: #6777ef !important;
  border-color: #6777ef !important;
}

// KPI

.progressbarkpi .p-progressbar-value {
  background-color: #67efd6 !important;
}

.progressbardays .p-progressbar-value {
  background-color: #6777ef !important;
}

.textLeft {
  text-align: left !important;
}

.textRight {
  text-align: right !important;
}

.textCenter {
  text-align: center !important;
}

// text grey
.textFade {
  color: #8892a0;
}

.bigSign {
  font-size: 3.25em !important;
}

.greenIcon {
  color:#00C853;
}

.redIcon {
  color:#BF360C;
}

.whitebg {
  background-color: #ffffff !important;
}

.safeEntryCheckIn{
  background-color: #00C853 !important;
}

.safeEntryCheckOut{
  background-color: #039BE5 !important;
}

.safeEntryCheckInOutCard{
  background-color: #F5F5F5 !important;
}

//  Badge Colours
.p-tag.p-tag-neutral{
  background-color:#aaaaaa;
}

// Notifications Sidebar
.p-sidebar{
  overflow:auto;
}

// Messages - Announcements
#Announcement .p-message{
  margin: 0.3rem 0;
}

#Announcement .p-message-wrapper{
  padding: 0.3rem;
}


// 18 Dec 2020 primereact update caused several icon / text / data css mess, these are rewrites for several primereact components
// See under datatable section above for new tags

// 1) DATATABLE

// Change default font size from 16px to 14px (was originally 14 but increased to 16 after update)
body .p-datatable{ // new
  font-size: 14px;
}

// Centres "No result found" message
.p-datatable .p-datatable-tbody .p-datatable-emptymessage > td {
  text-align:center;
}

// Datatable alignment based on how you want it
.datatable-centerHeader .p-datatable-thead > tr > th {
  text-align: center;
}
.datatable-centerContent .p-datatable-tbody > tr > td {
  text-align: center;
}

// 2) ICONS

// Calendar icon size on noticeboard
.pi.pi-calendar.p-c{
  font-size: 1.2em;
}
// Topbar icons size
// .layout-topbar .layout-topbar-icons button span.layout-topbar-icon{
//   font-size: 1.5em;
// }

// 3) DROPDOWNS

// Dropdown size and dropdown text size
body .p-inputtext {
  font-size:14px;
  padding:0.35rem;
}

// Dropdown font size
.p-dropdown .p-dropdown-panel{
  font-size:14px;
}

// Fixed sizes for certain dropdowns
.taxBodyUpdateLongDropdown{ // Tax Body Ypdate dropdowns with long content
  max-width:0rem; // Doesnt matter what rem, just that it conforms to the other ones and wont extend
}


// 4) CALENDAR

// Some Calendar alignment affected by invisible arrows, change individually in case other calendars need date pickers
#MonthCalendar .p-datepicker-next, #MonthCalendar .p-datepicker-prev{
  display:none;
}


// 5) Search bar icon alignment, no override

// 6) RADIO BUTTONS / CHECKBOX
.p-field-radiobutton, .p-field-checkbox > label, .p-field-radiobutton > label{
  margin-bottom:0px; // Fixes vertical alignment issues with labels and their respective radiobuttons/checkbox
}

// 7) DIALOG/MODAL not letting dropdowns/calendars overflow
.p-dialog-content{
  overflow: visible;
}

.businessCardDisplay{
	width: 100px;
	height: 100px;
	margin: 10px;
	border-radius: 50%;
	object-fit: cover;
}

// 8) Sidebar cannot scroll to end of content of smaller devices
@media(max-width:1024px){
  .layout-wrapper.layout-mobile-sidebar-active .layout-sidebar {
    padding-bottom: 100px;
  }
}

